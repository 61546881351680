/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useEffect, useState } from 'react';
import QueryString from 'qs';
import { useDispatch } from 'react-redux';
import mbpUtil from 'mbp-api-util';
import CardMedia from '@material-ui/core/CardMedia';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Close from '@material-ui/icons/Close';
import { useLocation } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import { trackEvent } from '../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';

const useStyles = makeStyles((theme) => ({
    dialogPaper: {
        margin: 'unset',
        maxWidth: '1200px',
        width: '100%',
        [theme.breakpoints.down('md')]: {
            maxWidth: 'calc(100% - 15px)',
        },
    },
    cardMediaRoot: {
        height: '90vh',
        width: '100%',
        border: 'none',
        maxHeight: '80vh',
    },
    closeButtonRoot: {
        '&:hover, &:focus, &:active': {
            cursor: 'pointer',
        },
    },
}));

const QUIZ_URL = mbpUtil.getEnv('APP_5LL_QUIZ_URL') || '';

const QuizDialog = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const location = useLocation();
    const [openDialog, setOpenDialog] = useState(false);

    const getQuiz = (showQuiz) => (typeof showQuiz === 'object' ? showQuiz[0] : showQuiz);

    const isQuizListingAd = (locationSearch) => {
        const params = QueryString.parse((locationSearch || '').replace('?', ''));
        const showQuiz = getQuiz(params.showquiz);
        return (
            showQuiz === '1'
            || params?.showQuiz?.includes('1')
        );
    };

    const setDialog = (isTrue) => {
        setOpenDialog(isTrue);
    };

    useEffect(() => {
        if (isQuizListingAd(location?.search)) {
            setDialog(true);
        } else {
            setDialog(false);
        }
    }, [location]);

    return (
        <Dialog
            open={openDialog}
            scroll="paper"
            onEntered={() => {
                dispatch(
                    trackEvent({
                        eventCategory: 'Quiz',
                        eventAction: 'Acton',
                        eventLabel: '',
                    }),
                );
            }}
            classes={{
                paper: classes.dialogPaper,
            }}
        >
            <DialogActions>
                <Close
                    data-testid="close-quiz-dialog-button"
                    className={classes.closeButtonRoot}
                    onClick={() => {
                        setDialog(false);
                    }}
                />
            </DialogActions>

            <CardMedia
                className={classes.cardMediaRoot}
                component="iframe"
                src={QUIZ_URL}
            />
        </Dialog>
    );
};

export default QuizDialog;
